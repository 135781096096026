@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-image: url('./stern-hintergrund.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
body, p, li, ul, .allergens {
  font-family: 'FiraSans', sans-serif;
}
.dark body {
  background-color: #000; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, .price, .tab, .button-gagalin, button {
  font-family: 'Gagalin', sans-serif;
}
@font-face {
  font-family: 'Gagalin';
  src: url('fonts/Gagalin-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'FiraSans';
  src: url('fonts/FiraSans-Regular.ttf') format('truetype');
}

/* Animationen */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.tab {
  animation: fadeInUp 0.5s ease-in-out;
}
.neon-effect {
  box-shadow: 0 0 10px rgba(194, 48, 49, 0.5), 0 0 20px rgba(194, 48, 49, 0.5), 0 0 30px rgba(194, 48, 49, 0.5), 0 0 40px rgba(194, 48, 49, 0.5);
  animation: neonGlow 1.5s infinite alternate;
}

@keyframes neonGlow {
  from {
    box-shadow: 0 0 5px rgba(194, 48, 49, 0.5), 0 0 10px rgba(194, 48, 49, 0.5), 0 0 15px rgba(194, 48, 49, 0.5), 0 0 20px rgba(194, 48, 49, 0.5);
  }
  to {
    box-shadow: 0 0 20px rgba(194, 48, 49, 0.5), 0 0 30px rgba(194, 48, 49, 0.5), 0 0 40px rgba(194, 48, 49, 0.5), 0 0 50px rgba(194, 48, 49, 0.5);
  }
}
.notification {
  animation: fadeInOut 3s ease-in-out;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #38a169; /* Grüner Hintergrund */
  color: white; /* Weißer Text */
  padding: 10px 20px; /* Etwas Padding */
  border-radius: 5px; /* Abgerundete Ecken */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Etwas Schatten */
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(-20px) translateX(-50%);
  }
  10% {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
  90% {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px) translateX(-50%);
  }
}

.product-item.highlight {
  animation: highlightEffect 1s ease-in-out;
}

@keyframes highlightEffect {
  0% {
    background-color: #c23031; /* Grüner Hintergrund */
  }
  100% {
    background-color: transparent;
  }
}

@keyframes highlightEffect {
  0% {
    background-color: #c23031; /* Grüner Hintergrund */
  }
  100% {
    background-color: transparent;
  }
}
.button {
  background-color: #c23031; /* Grüner Hintergrund */
  color: white; /* Weißer Text */
  padding: 9px 15px; /* Etwas Padding */
  border-radius: 5px; /* Abgerundete Ecken */
  box-shadow: 0 4px #902222; /* 3D-Effekt */
  transition: all 0.2s ease; /* Sanfter Übergang */
}

.button:focus {
  outline: none; /* Entferne den Standard-Fokus-Rahmen */
  box-shadow: 0 4px #c23031, 0 0 0 4px rgba(56, 161, 105, 0.5); /* Füge einen Fokus-Rahmen hinzu */
}

.button:active {
  box-shadow: 0 2px #c23031; /* Reduziere den Schatten, um den Button "eingedrückt" aussehen zu lassen */
  transform: translateY(2px); /* Verschiebe den Button nach unten */
}
.dressing-button {
  background-color: white;
  color: black;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.dressing-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.dressing-button.selected {
  background-color: red;
  color: white;
}